import * as core from '@mt-webpages/core'
import * as subscription from './subscription'
import * as hubspotEvent from './hubspotEvent'
import * as hubspotFormEvent from './hubspotFormEvent'
import * as mtWebpagesWebEvent from './mtWebpagesWebEvent'
import * as eventEmitter from './eventEmitter'
import * as hubspotSubmitFormResult from './hubspotSubmitFormResult'
import * as hubspotFormSubmitFieldErrorTranslation from './hubspotFormSubmitFieldErrorTranslation'
import * as httpClientService from 'src/data/httpClientService'
import * as recaptchaClientService from 'src/data/recaptchaClientService'
import * as mtGtmService from 'src/data/mtGtmService'
import * as mtWebpagesApiClientService from 'src/data/mtWebpagesApiClientService'
import * as mtWebpagesWebSessionService from 'src/data/mtWebpagesWebSessionService'
import * as mtWebpagesWebBootstrapService from 'src/data/mtWebpagesWebBootstrapService'
import * as httpClient from 'src/lib/httpClient'
import * as recaptchaClient from 'src/lib/recaptchaClient'
import * as locale from './locale'

export type MtWebpagesWebContext = {
  window: Window & typeof globalThis
  logger: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
  mtGtm: mtGtmService.MtGtmService
  httpClient: httpClientService.HttpClientService
  recaptchaClient: recaptchaClientService.RecaptchaClientService
  hubspotUtkCookieName: string
  hubspotEventEmitter: eventEmitter.EventEmitter<hubspotEvent.HubspotEvent>
  hubspotFormEventEmitter: eventEmitter.EventEmitter<hubspotFormEvent.HubspotFormEvent>
  mtWebpagesWebEventEmitter: eventEmitter.EventEmitter<mtWebpagesWebEvent.MtWebpagesWebEvent>
  mtWebpagesApiClient: mtWebpagesApiClientService.MtWebpagesApiClientService
  mtWebpagesWebBootstrap: mtWebpagesWebBootstrapService.MtWebpagesWebBootstrapService
  mtWebpagesWebSession: mtWebpagesWebSessionService.MtWebpagesWebSessionService
  hubspotFormConfig: {
    locale: locale.Locale
    defaultObjectTypeId: core.data.hubspotFormFieldObjectTypeId.HubspotFormFieldObjectTypeId
    formElementQuery: string
    formSuccessElementQuery: string
    formFailureElementQuery: string
    fieldErrorTranslation: hubspotFormSubmitFieldErrorTranslation.HubspotFormSubmitFieldErrorTranslation
    formFieldErrorDatasetIdentifier: string
    formInvalidFieldCssClassname: string
    formfieldErrorListContainerCssClassname: string
    formfieldErrorListCssClassname: string
    formfieldErrorListItemCssClassname: string
    formSubmitLoadingClassname: string
    callback: (result: hubspotSubmitFormResult.HubspotSubmitFormResult) => void
  }
}

export const defaultContext: MtWebpagesWebContext = {
  logger: () => null,
  window: globalThis.window,
  mtGtm: {
    sendEvent: () => null
  },
  httpClient: httpClient.toHttpClientService({
    client: fetch
  }),
  hubspotUtkCookieName: 'hubspotutk',
  /* eslint-disable */
  hubspotEventEmitter: eventEmitter.empty,
  /* eslint-disable */
  hubspotFormEventEmitter: eventEmitter.empty,
  recaptchaClient: {
    getToken: () => Promise.resolve('')
  },
  mtWebpagesWebEventEmitter: eventEmitter.empty,
  /* eslint-enable */
  /* eslint-disable */
  mtWebpagesApiClient: {
    postHubspotForm: async () => {},
    getSessionHubspotContact: async () => ({ contact: { properties: {} } }),
    setSessionHubspotContact: async () => {}
  },
  mtWebpagesWebSession: {
    submitHubspotForm: async () => {},
    getHubspotContact: async () => ({ properties: {} }),
    setHubspotContact: async () => {}
  },
  /* eslint-enable */
  mtWebpagesWebBootstrap: {
    setup: () => subscription.empty
  },
  hubspotFormConfig: {
    locale: 'ja',
    defaultObjectTypeId: '0-1',
    formElementQuery: 'form[data-hubspot-form-id]',
    formSuccessElementQuery: '.w-form-done',
    formFailureElementQuery: '.w-form-fail',
    fieldErrorTranslation: hubspotFormSubmitFieldErrorTranslation.defaultTranslations,
    formFieldErrorDatasetIdentifier: 'hubspotfielderror',
    formInvalidFieldCssClassname: 'mt-webpages-form-invalid-field',
    formfieldErrorListContainerCssClassname: 'mt-webpages-form-field-error-list-container',
    formfieldErrorListCssClassname: 'mt-webpages-form-field-error-list',
    formfieldErrorListItemCssClassname: 'mt-webpages-form-field-error-list-item',
    formSubmitLoadingClassname: 'mt-webpages-form-submit-loading',
    callback: () => null
  }
}
